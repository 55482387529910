import React from 'react'
import './student.css'
import mobileAndWeb from '../../assets/mobile&web.png'

import mtnlogo from '../../assets/mtnlogo.jpeg'
import orangelogo from '../../assets/orangelogo.png'
import paycardlogo from '../../assets/paycardlogo.png'

import students from '../../assets/students.png'


import {AiOutlineArrowRight} from 'react-icons/ai'

const student = () => {
  return (
    <section id='student'>
    <div className="container student__container">
   
  

    {<div className='studentCTA'>
    
 
    
    <img  className='studentboximg' src={students} alt="bonecole app in use"/>
   
       
        
        </div>}
       





      <div className="studentExplainer">
      <h4 style={{fontSize:"1.7rem",width:"30rem"}}> PARENTES</h4>

         <p style={{fontSize:"1.2rem",width:"24rem"}}>
         Notre gestion globale plateforme

        
         construite pour les élèves de la maternelle à la 12e année
        </p>

        <button className="btn btn-primary" style={{marginTop:"0rem"}}>
        APPRENDRE  PLUS &nbsp; {/*<span style={{position:"relative",top:"3.5px"}}><AiOutlineArrowRight/></span>*/}
         </button>
      </div>
     
 



      



</div>
    </section>
  )
}

export default student