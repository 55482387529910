import React from 'react'
import './experienceHome.css'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import agileBased from  '../../assets/agileBased.png'
import businessOriented from  '../../assets/businessOriented.png'
import collaboration from  '../../assets/collaboration.png'
import experienceHome from  '../../assets/experience.png'
import userFriendly from  '../../assets/userFriendly.png'
import scalable from  '../../assets/scalable.png'

import manage from  '../../assets/manage.png'
import make from  '../../assets/make.png'
import maintain from  '../../assets/maintain.png'
import monitor from  '../../assets/monitor.png'

import gradSuccess from '../../assets/grad-success.jpeg'


const experience = () => {
  return (
    <section  id='experienceHome'>

 

   {/*<h1 className= "shifting-marginBottom" style={{scale:"0.7"}}>Benefits Of Our Web Application Development Services</h1>*/}
   
   <div style={{scale:"0.9"}} className="container experienceHome__container">




   <div className="experienceHome__image__container">

      
      
      <img src={gradSuccess} alt="" />
      
  </div>
   
      <div className="experienceHome__backend">

     <div  className="small-image-home" >
      <img src={manage} alt="" />
     </div>

       <div className="large-desc-home"  >
         {/*<h3>Web Development</h3>*/}
         <p>
         <b> Optimisez </b> la gestion administrative de votre école en automatisant les tâches et en optimisant l'allocation des ressources.
         </p>
       </div>
       
      </div>

       <div className="experienceHome__backend">
        
       <div className="small-image-home">
      <img src={maintain} alt="" />
     </div>
      
      
     <div className="large-desc-home">
       {/*<h3>Mobile Development</h3>*/}
       <p>
      <b> Obtenez </b> une vision instantanée et précise des finances de votre établissement (revenus et dépenses)
      </p>
      </div>

       </div>

       <div className="experienceHome__backend">
      
       <div className="small-image-home">
      <img src={make} alt="" />
     </div>
      
     <div className="large-desc-home">
      {/* <h3>Product Design (UI/UX)</h3>*/}
       <p>
       <b>Renforcez</b>  la communication école - parents, élèves et enseignants grâce à nos outils de collaboration et messagerie, pour un partage instantané des informations et notifications.
       </p>
      </div>

       </div>

       <div className="experienceHome__backend">
       
       <div className="small-image-home">
        <img src={monitor} alt="" />
      </div>
       
       
      <div className="large-desc-home">
       {/*<h3>Scalable Architecture</h3>*/}
       <p>
       <b>Consolidez</b>  une base de données sécurisée et obtenez des rapports détaillés sur les performances académiques, les présences, et autres indicateurs clés
       </p>
      </div>

       </div>

    

      
   </div>

     
     {/* <center className= "shifting-marginTop" >
      <Link to= {'/page2'}>
       <button className='btn btn-primary'>Get Started</button>
       </Link>
      </center> */}


     
  </section>
  )
}

export default experience