import React from 'react'
import './payment.css'
import mobileAndWeb from '../../assets/mobile&web.png'

import mtnlogo from '../../assets/mtnlogo.jpeg'
import orangelogo from '../../assets/orangelogo.png'
import paycardlogo from '../../assets/paycardlogo.png'


import {AiOutlineArrowRight} from 'react-icons/ai'

const Payment = () => {
  return (
    <section id='payment'>
    <div className="container payment__container">
   

      <div className="paymentExplainer">
      <h4 style ={{fontSize:"1.7rem",width:"30rem"}}> LA DIGICOLE DE BONECOLE</h4>
    

         <p style ={{fontSize:"1.3rem"/*,width:"27rem"*/}}>
         Nous proposons une solution intégrée qui fusionne les différents composants de l'écosystème scolaire pour un fonctionnement harmonieux : un module administratif (pour la direction et l'administration), un portail dédié aux parents et aux élèves, ainsi qu'un espace réservé aux enseignants.
        </p>

        <button className="btn btn-black" style={{marginTop:"0rem"}}>
        APPRENDRE PLUS &nbsp; {/*<span style={{position:"relative",top:"3.5px"}}><AiOutlineArrowRight/></span>*/}
         </button>
      </div>
     
     {/*<div className='paymentCTA'>
    
       <img  className='CTAbox' src={countPennies} alt="counting coins"/>
     

     <div className='clip-path-bottom-right'></div>
     <div className='clip-path-bottom-left'></div>
     <div className='clip-path-top-left'></div>
</div>*/}  


{/*<div className='paymentCTA'>
    
 
<img  className='Paymentboximg' src={mtnlogo} alt="mtn logo"/>
<img  className='Paymentboximg' src={paycardlogo} alt="paycard logo"/>
<img  className='Paymentboximg' src={orangelogo} alt="orange logo"/>

   
    
    </div>*/}
   
      



</div>
    </section>
  )
}

export default Payment