import React from 'react'

import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import Payment from './components/payment/Payment'
import Student from './components/students/Student'

import RangeTable from './components/rangeTable/RangeTable'
import Experience from './components/experience/Experience'
import Choice from './components/choice/Choice'
import Solutions from './components/solutions/Solutions'
import Testimonials from './components/testimonials/Testimonials'
import Footer from './components/footer/Footer'
import Features from './components/features/Features'
import Projects from './components/projects/Projects'
import Faq from './components/Faq/Faq'

const HomePage = () => {
 
    return (
        <>
        <Nav/>
        <Header />
        <Experience />
        <Payment />
        <Student />
        
        
        {/*<RangeTable/>*/}
        {/*<Features/>*/}
        {/*<Choice/>*/}
        {/*<Solutions/>*/}
        {/*<Testimonials/>*/}
        {/*<Projects/>*/}
        <Footer/>
        </>
      )
}

export default HomePage