import React ,{useState}from 'react'
import { Link } from 'react-router-dom'
import './header.css'
import laptop from '../../assets/demonstration.jpeg'

import LoanSlider from './LoanSlider'



const Header = () => {

   const [input,setInput] = useState(1000)

   const handleChange = (e) => {
    setInput(e.target.value);
    console.log("this is the parent speaking")
  };

  return (
    <div className='headerHome'>
    <div className=" headerHome__container-1">
      
   
      <div className="headerHomeExplainer"   >
      <h2  style ={{fontSize:"1.1rem",width:"32rem"}} className="text-light"> 
     {/* Planification des ressources 
      
  éducatives (ERP) pour les écoles K-12*/}
   
   Entamez la transformation digitale de votre établissement 
   avec notre Solution de Gestion Scolaire intégrée - Digicole.

       </h2>

         <p className="text-light" style={{fontSize:"1rem",width:"22rem"}}>
         {/*Solutions intégrées pour des opérations scolaires fluides. Simplifiez votre processus avec notre solution ERP.*/}
         Solution tout en un pour piloter la gestion quotidienne de votre établissement scolaire.
         
        </p>

         <button className="btn btn-primary header-btn-scale-down">
         APPRENDRE  PLUS
         </button>
      </div>
     
     <div className='headerHomeCTA'>
     <img  className='CTAHomeboximg' src={laptop} alt="two people sharing an ipad"/>
     
     </div>

    </div>
    </div>
  )
}

export default Header